import React, {useState,useEffect} from 'react';
import Header from './component/Header';
import NavBar from './component/Navbar';
import {css} from '@emotion/react';
import { PropagateLoader } from 'react-spinners';
import Product from './component/Product';
import About from './component/About';
import Contact from './component/Contact';
function App() {
 const [loading,setLoading]=useState(false);
 const override =css`
 display:block;
 border-color:red;
 margin-top:20%;

 text-align:center;
 `;

 useEffect(()=>{
   setLoading(true)
   setTimeout(()=>{
     setLoading(false)
   },5000);
 },[])

  return (
    <div className="App">
    {
      loading ? <PropagateLoader color={"#3d2514"} loading={loading} css={override} size={40}/>
      :
      <div>
      <NavBar/>
      <Header/>
      <Product/>
      <About/>
      <Contact/>
     
      </div>
     
    }
   
     
     </div>
  );
}

export default App;
