import React from 'react';
function ProductBox(props){
    return(
        <div className="a-box">
        <div className="a-b-img">
        <img src={props.img} alt=""></img>
        </div>
        <div className="a-b-text">
        <h2>{props.title}</h2>
        <button className="productbox-btn"> Order now</button>
        </div>
        
        </div>
    )
}
export default ProductBox;