import React from 'react';
import ProductBox from './ProductBox';
import pimg1 from '../images/s1.png';
import pimg2 from '../images/s2.png';
function Product(){
    return(
        <div id="products">
         <h1>ChOOSE & ENJOY</h1>
         <p>Lorem , ipsum color sit amet consecturer adipisicing elit</p>
         <div className="a-container">
         
         <ProductBox img={pimg1} title="Family Combo"/>
         <ProductBox img={pimg2} title="Top Picks"/>
         <ProductBox img={pimg1} title="Whopper"/>
         </div>
        </div>
    )
}
export default Product;