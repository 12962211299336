import React from 'react';
import aboutimg from '../images/about.png';
function About(){
    return(
        <div id="about">
        <div className="about-text">
        <h1>UPCOMING EVENT</h1>
        <p>The secret to saving money at Burger King every month,You can get free Burger King burgers for life - but there's a catch</p>
        <button>Read More</button>
        </div>
        <div className="about-image">
        <img src={aboutimg} alt=""></img>
        </div>
        </div>
    )
}
export default About;