import React from 'react';
function Header(){

    return(

        <div id="main">
        <div className="header-heading">
       
        <h3>ITS's GREAT TIME FOR A GOOD TASTE OF BURGER</h3>
        <h1><span>BURGER</span> FOR<br/>WEEK</h1>
        <p className="details"> </p>
        <div className="header-btns">
        <a href='#' className="header-btn">Order</a>
        </div>
        </div>
        </div>
    )
}
export default Header;